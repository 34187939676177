

























import { Action } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        PagePresetGuest: () => import('@/layouts/presets/Guest.vue'),
        FormBase: () => import('@/components/forms/Base.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        ContactToggle: () => import('@/components/contact/ContactToggle.vue'),
        ElButton: () => import('@/components/elements/Button.vue'),
    },
})
export default class PageResetPassword extends Vue {
    @Action('user/resetPassword') resetPassword!: (payload: ResetPasswordPayload) => Promise<Auth>;

    i18n: string = 'views.reset_password';

    success: boolean = false;

    $refs!: Vue['$refs'] & {
        resetPasswordForm: FormClass;
    };

    payload: ResetPasswordPayload = {
        email: undefined,
        password: undefined,
        repeat_password: undefined,
        password_reset_token: undefined,
    };

    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const email = urlParams.get('email');
        if (token != null) {
            this.payload.password_reset_token = token;
        }
        if (email != null) {
            this.payload.email = email;
        }
    }

    private resetPasswordSubmit() {
        const $form: FormClass = this.$refs.resetPasswordForm;
        this.resetPassword(this.payload)
            .then(() => {
                $form.handleSuccess();
                this.$router.push({ name: 'login', query: { action: 'password-changed' } });
            })
            .catch((e: ErrorResponse) => $form.handleErrors(e));
    }
}
